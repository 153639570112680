import { CountryCode } from "../../../settings/countries";
import { getLocaleByCountry } from "../../../utils/locale-configuration-utils";

export const useHealthProductFeatures = (
  data: Record<string, string[] | string>[],
  country: CountryCode
): Record<string, string[] | string>[] => {
  return data.filter((item) => item.locale === getLocaleByCountry(country));
};

export const useHealthProductConditions = (
  data: Record<string, string[] | string>[],
  country: CountryCode,
  species: string,
  type: string
): Record<string, string[] | string> | undefined => {
  return data.find(
    (item) =>
      item.type === type &&
      item.node_locale === country.toLowerCase() &&
      item.species.includes(species)
  );
};
