import { CalendarDate, getLocalTimeZone, today } from "@internationalized/date";
import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { Dispatch, ReactElement, SetStateAction } from "react";
import { DateValue } from "react-aria";

import SectionTitle from "../../../../../molecules/section-title/SectionTitle";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { isPetYoungerThanMinAge } from "../../../../../settings/pet";
import { Events, track } from "../../../../../utils/analytics";
import useVisibilityTracker from "../../../../../utils/hooks/useVisibilityTracker";
import DatePicker from "../../molecules/date-picker/DatePicker";
import * as styles from "./PolicyStartDateSelector.module.scss";

interface PolicyStartDateSelectorProps {
  initialValue?: CalendarDate;
  updateValue: (value: string) => void;
  hasValidationError: boolean;
  setHasValidationError: Dispatch<SetStateAction<boolean>>;
  isDisabled?: boolean;
  subtitle?: string;
  customMinValue?: CalendarDate;
}

const PolicyStartDateSelector = ({
  initialValue,
  updateValue,
  hasValidationError,
  setHasValidationError,
  isDisabled,
  customMinValue,
}: PolicyStartDateSelectorProps): ReactElement => {
  const { ref } = useVisibilityTracker("policy-start-date-selector", {
    threshold: 0.5,
  });
  const { t } = useTranslation();
  const result = useResult();
  const localDate = today(getLocalTimeZone());
  const minValue =
    customMinValue ||
    new CalendarDate(localDate.year, localDate.month, localDate.day).add({
      days: 1,
    });

  const maxValue = new CalendarDate(localDate.year, localDate.month, localDate.day).add({
    months: 6,
  });
  const petIsYoungerThanMinAge = result && isPetYoungerThanMinAge(result?.pet_birth_date);

  const handleDateChange = (value: DateValue): void => {
    if (!value) {
      return;
    }

    const answer = `${value.day}/${value.month}/${value.year}`;

    track(Events.DATE_SELECTED, {
      dateQuestion: "Policy Start Date",
      answer,
    });

    updateValue(value.toString());
  };

  return (
    <section ref={ref} className={styles.policyStartDateSelector}>
      <SectionTitle
        title={
          petIsYoungerThanMinAge
            ? t("pg_quotes.policy_start_date_selector.title.pet_too_young")
            : t("pg_quotes.policy_start_date_selector.title")
        }
        {...(petIsYoungerThanMinAge && {
          subtitle: parse(
            t("pg_quotes.policy_start_date_selector.subtitle.pet_too_young", {
              petName: result?.pet_name,
            })
          ),
        })}
        adoptionClassName={styles.sectionTitle}
      />
      <div className={styles.datepickerWrapper}>
        <DatePicker
          label={t("policy_start_date_datepicker.field.title")}
          value={initialValue || minValue}
          onChange={(e: DateValue) => handleDateChange(e)}
          commitValidation={(validation) => setHasValidationError(validation)}
          minValue={minValue}
          maxValue={maxValue}
          isDisabled={isDisabled}
        />
        {hasValidationError && (
          <p className={cn(styles.errorMessage)}>
            {t("policy_start_date_datepicker.validation_error_message")}
          </p>
        )}
      </div>
    </section>
  );
};

export default PolicyStartDateSelector;
