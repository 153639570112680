import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { ReactElement, useState } from "react";

import { DealEventProperties } from "../../organisms/deal-event-properties/domain/deal-event-properties";
import { useDealEventProperties } from "../../organisms/deal-event-properties/use-cases/deal-event-properties-use-cases";
import { Events, track } from "../../utils/analytics";
import * as styles from "./CheckList.module.scss";

export enum CheckListVariantsName {
  isSuccessful = "isSuccessful",
  isNegative = "isNegative",
}

interface CheckListProps {
  id: string;
  items: string[] | ReactElement[];
  adoptionClassName?: string;
  variantName?: CheckListVariantsName;
  isCollapsable?: boolean;
}

const CheckList = ({
  id,
  items,
  adoptionClassName,
  variantName,
  isCollapsable = false,
}: CheckListProps): ReactElement => {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const dealEventProperties: DealEventProperties | null = useDealEventProperties();

  const toggle = (): void => {
    const properties = { block: id, ...dealEventProperties };
    const eventName = !isExpanded ? Events.BLOCK_EXPANDED : Events.BLOCK_COLLAPSED;

    setExpanded(!isExpanded);
    track(eventName, properties);
  };

  return (
    <section
      className={cn(styles.checkListSection, adoptionClassName, {
        [styles[variantName as string]]: variantName,
        [styles.isCollapsable]: isCollapsable,
      })}
    >
      <ul
        id={id}
        className={cn("check-list", styles.checkList, {
          [styles.shortedList]: !isExpanded,
        })}
      >
        {items.map((item, index) => (
          <li key={index}>
            <span>{parse(item)}</span>
          </li>
        ))}
      </ul>
      {isCollapsable && (
        <button
          type={"button"}
          className={cn(styles.expandableButton, { [styles.isHidden]: items.length <= 7 })}
          onClick={toggle}
          aria-controls={id}
          aria-expanded={isExpanded}
        >
          {isExpanded ? t("common.see_less") : t("common.see_more")}
        </button>
      )}
    </section>
  );
};
export default CheckList;
