import { useTranslation } from "gatsby-plugin-react-i18next";
import { Dispatch, ReactElement, SetStateAction, useEffect, useRef } from "react";
import { DateValue, I18nProvider, useDatePicker } from "react-aria";
import { useDatePickerState } from "react-stately";

import DatePickerField from "../../molecules/date-picker-field/DatePickerField";
import DatePickerCalendar from "../date-picker-calendar/DatePickerCalendar";

interface DatePickerProps {
  label: string;
  value: DateValue;
  onChange: Dispatch<SetStateAction<DateValue>>;
  commitValidation: Dispatch<SetStateAction<boolean>>;
  minValue?: DateValue;
  maxValue?: DateValue;
  isDisabled?: boolean;
}

const DatePicker = (props: DatePickerProps): ReactElement => {
  const { label, commitValidation } = props;
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const state = useDatePickerState(props);
  const datePickerRef = useRef(null);
  const { groupProps, labelProps, fieldProps, buttonProps, calendarProps } = useDatePicker(
    props,
    state,
    datePickerRef
  );

  const openCalendar = (): void => {
    state.setOpen(!state.isOpen);
  };

  useEffect((): void => {
    commitValidation(state.isInvalid);
  }, [commitValidation, state.isInvalid]);

  return (
    <I18nProvider locale={locale}>
      <DatePickerField
        groupRef={datePickerRef}
        groupProps={groupProps}
        buttonProps={buttonProps}
        onClick={openCalendar}
        onButtonPress={openCalendar}
        labelProps={labelProps}
        label={label}
        isOpen={state.isOpen}
        isDisabled={props.isDisabled}
        {...fieldProps}
      />

      {state.isOpen && !props.isDisabled && (
        <DatePickerCalendar
          state={state}
          calendarProps={calendarProps}
          triggerRef={datePickerRef}
        />
      )}
    </I18nProvider>
  );
};

export default DatePicker;
