import { createCalendar, getWeeksInMonth } from "@internationalized/date";
import cn from "classnames";
import { ReactElement, useRef } from "react";
import { useButton, useCalendar, useCalendarCell, useCalendarGrid, useLocale } from "react-aria";
import { useCalendarState } from "react-stately";

import * as styles from "./Calendar.module.scss";

const CalendarButton = (props): ReactElement => {
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);

  return (
    <button {...buttonProps} ref={ref} className={props.adoptionClassName}>
      {props.children}
    </button>
  );
};

const CalendarCell = ({ state, date }): ReactElement => {
  const ref = useRef(null);
  const { cellProps, buttonProps, isSelected, isOutsideVisibleRange, isDisabled, formattedDate } =
    useCalendarCell({ date }, state, ref);

  const handleClick = (event): void => {
    event.stopPropagation();
    state.setValue(date);
  };

  return (
    <td {...cellProps}>
      <div
        onClick={handleClick}
        role={buttonProps.role}
        aria-label={buttonProps["aria-label"]}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={cn(styles.cell, {
          [styles.selected]: isSelected,
          [styles.disabled]: isDisabled,
        })}
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            handleClick(event);
          }
        }}
      >
        {formattedDate}
      </div>
    </td>
  );
};

const Calendar = (props): ReactElement => {
  const { locale } = useLocale();
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(props, state);
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <div {...calendarProps} className={styles.calendar}>
      <div className={styles.header}>
        <h2>{title}</h2>
        <CalendarButton {...prevButtonProps} adoptionClassName={styles.prevButton}>
          <svg className={cn(styles.icon, "icon icon--arrowdown")} aria-hidden="true">
            <use href="#icon-arrowdown"></use>
          </svg>
        </CalendarButton>
        <CalendarButton {...nextButtonProps} adoptionClassName={styles.nextButton}>
          <svg className={cn(styles.icon, "icon icon--arrowdown")} aria-hidden="true">
            <use href="#icon-arrowdown"></use>
          </svg>
        </CalendarButton>
      </div>
      <table {...gridProps}>
        <thead {...headerProps}>
          <tr>
            {weekDays.map((day, index) => (
              <th key={index}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
            <tr key={weekIndex}>
              {state
                .getDatesInWeek(weekIndex)
                .map((date, i) =>
                  date ? <CalendarCell key={i} state={state} date={date} /> : <td key={i} />
                )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Calendar;
