// extracted by mini-css-extract-plugin
export var healthProductCard = "HealthProductCard-module--healthProductCard--OyDEe";
export var imageWrapper = "HealthProductCard-module--imageWrapper--dXaJH";
export var name = "HealthProductCard-module--name--qfO5r";
export var price = "HealthProductCard-module--price--HRS7a";
export var discountedPriceWrapper = "HealthProductCard-module--discountedPriceWrapper--CS+3g";
export var discountedPrice = "HealthProductCard-module--discountedPrice--OYVO1";
export var discountedPriceMessage = "HealthProductCard-module--discountedPriceMessage--2Z7u8";
export var discountBadge = "HealthProductCard-module--discountBadge--7F05C";
export var button = "HealthProductCard-module--button--QuPsg";
export var bottomButton = "HealthProductCard-module--bottomButton--58rBZ";
export var featuresCheckList = "HealthProductCard-module--featuresCheckList--n7AeK";
export var featuresNote = "HealthProductCard-module--featuresNote--e5cO3";
export var benefitsWrapper = "HealthProductCard-module--benefitsWrapper--XdmGQ";
export var benefitTitle = "HealthProductCard-module--benefitTitle--TMqyj";
export var isExpanded = "HealthProductCard-module--isExpanded--hMlMW";
export var barkibuCardBanner = "HealthProductCard-module--barkibuCardBanner--QoOl4";
export var moreInfoMessage = "HealthProductCard-module--moreInfoMessage--3vUNF";
export var baiMessage = "HealthProductCard-module--baiMessage--2AONo";
export var expandableButton = "HealthProductCard-module--expandableButton---nR9V";
export var footer = "HealthProductCard-module--footer--TopYN";