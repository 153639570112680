import cn from "classnames";
import { Form, Formik, FormikHelpers } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement, ReactNode, useState } from "react";

import Button from "../../../../../atoms/button/Button";
import TextFormControl from "../../../../../atoms/text-form-control/TextFormControl";
import {
  usePhoneNumber,
  UsePhoneNumberFormValues,
} from "../../../../../core/domain/hooks/usePhoneNumber";
import { sanitizePhone } from "../../../../../core/utils/sanitizers";
import SectionTitle from "../../../../../molecules/section-title/SectionTitle";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { CountryCode } from "../../../../../settings/countries";
import { States } from "../../../../../settings/state";
import { Events, track } from "../../../../../utils/analytics";
import { getCurrentUrlWithoutParams } from "../../../../../utils/browser-features";
import { getPhoneNumberPrefix } from "../../../../../utils/locale-configuration-utils";
import WhatsAppIcon from "../../images/common/whatsapp-decorated.svg";
import Dialog from "../../molecules/dialog/Dialog";
import * as styles from "./WhatsAppDialog.module.scss";

interface WhatsAppDialogProps {
  countryCode: CountryCode;
  eventSender: string;
  label: ReactNode;
}

const WhatsAppDialog = ({ countryCode, eventSender, label }: WhatsAppDialogProps): ReactElement => {
  const { t } = useTranslation();
  const [whatsappStatus, setWhatsappStatus] = useState<string>("");
  const phoneNumberPrefix = getPhoneNumberPrefix(countryCode);
  const result = useResult();
  const {
    initialValues,
    validationSchema,
    phoneNumberLength: { min: phoneMinLength, max: phoneMaxLength },
  } = usePhoneNumber({
    defaultCountryCode: phoneNumberPrefix,
    country: countryCode,
    isWhatsAppNumber: true,
    isPhoneNumberRequired: false,
  });

  return (
    <Dialog label={label} analyticsLabel={eventSender}>
      {(close) => {
        const closeDialog = (): void => {
          close();
          setWhatsappStatus("");
        };

        return (
          <section className={styles.dialog}>
            <WhatsAppIcon />
            {whatsappStatus !== States.SUCCESS_STATE && (
              <>
                <SectionTitle
                  title={t(
                    "pg_quotes.alternative_health_product_selector.whatsapp_dialog.phone_number_form.title"
                  )}
                  subtitle={t(
                    "pg_quotes.alternative_health_product_selector.whatsapp_dialog.phone_number_form.subtitle"
                  )}
                  adoptionClassName={styles.sectionTitle}
                />
                <Formik
                  initialValues={initialValues}
                  validateOnMount
                  validationSchema={validationSchema}
                  onSubmit={(
                    values: UsePhoneNumberFormValues,
                    { setSubmitting }: FormikHelpers<UsePhoneNumberFormValues>
                  ): void => {
                    setSubmitting(true);

                    const sanitizedPhone = sanitizePhone(values.phone, countryCode);
                    const fullPhoneNumber = `+${values.countryCode}${sanitizedPhone}`;

                    track(Events.CLICKED_CTA, {
                      eventSender: `${eventSender} - Bai Button`,
                      countryCode,
                      phone: fullPhoneNumber,
                      petName: result?.pet_name,
                      pageWithoutParams: getCurrentUrlWithoutParams(),
                    });

                    setTimeout(() => {
                      setSubmitting(false);
                      setWhatsappStatus(States.SUCCESS_STATE);
                    }, 2000);
                  }}
                >
                  {({ isSubmitting, setFieldValue, isValid, values }) => (
                    <Form className={styles.whatsappDialogForm}>
                      <div className={styles.phoneNumberFieldsWrapper}>
                        <TextFormControl
                          adoptionClassName={styles.phoneNumberPrefix}
                          name="countryCode"
                          label={t("pet_parent.access.country_code.field.title")}
                          type="tel"
                          maxLength={4}
                          onChange={(e) =>
                            setFieldValue("countryCode", e.target.value.replace(/\D/g, ""))
                          }
                        />
                        <TextFormControl
                          name="phone"
                          label={t("pet_parent.access.phone.field.title")}
                          type="tel"
                          minLength={phoneMinLength}
                          maxLength={phoneMaxLength}
                          onChange={(e) =>
                            setFieldValue("phone", e.target.value.replace(/\D/g, ""))
                          }
                        />
                      </div>
                      <Button
                        type="submit"
                        adoptionClassName={styles.continueButton}
                        disabled={isSubmitting || !isValid || values.phone.length === 0}
                        isLoading={isSubmitting}
                      >
                        {t("common.cta.next")}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </>
            )}
            {whatsappStatus === States.SUCCESS_STATE && (
              <>
                <SectionTitle
                  title={t(
                    "pg_quotes.alternative_health_product_selector.whatsapp_dialog.whatsapp_sent"
                  )}
                  adoptionClassName={styles.sectionTitle}
                />
                <Button type="button" adoptionClassName={styles.closeButton} onClick={closeDialog}>
                  {t("pg_quotes.alternative_health_product_selector.whatsapp_dialog.close_button")}
                </Button>
              </>
            )}
            <svg
              className={cn(styles.icon, "icon")}
              role="img"
              aria-hidden="true"
              onClick={closeDialog}
            >
              <use href="#icon-cross"></use>
            </svg>
          </section>
        );
      }}
    </Dialog>
  );
};

export default WhatsAppDialog;
