import { createCalendar } from "@internationalized/date";
import { DateFieldState, DateSegment, useDateFieldState } from "@react-stately/datepicker";
import cn from "classnames";
import { HTMLAttributes, ReactElement, ReactNode, RefObject, useRef, useState } from "react";
import { useButton, useDateField, useDateSegment, useLocale } from "react-aria";

import * as styles from "./DatePickerField.module.scss";

interface DatePickerFieldProps {
  isOpen: boolean;
  groupRef: RefObject<HTMLDivElement>;
  groupProps: HTMLAttributes<HTMLDivElement>;
  label: ReactNode;
  labelProps: HTMLAttributes<HTMLSpanElement>;
  buttonProps: HTMLAttributes<HTMLButtonElement>;
  onButtonPress: () => void;
  onClick: () => void;
  isDisabled?: boolean;
}

interface DatePickerFieldSegmentProps {
  segment: DateSegment;
  state: DateFieldState;
}

const DatePickerFieldSegment = ({ segment, state }: DatePickerFieldSegmentProps): ReactElement => {
  const ref = useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      className={cn(styles.segment, {
        [styles.placeholder]: segment.isPlaceholder,
      })}
    >
      {segment.text}
    </div>
  );
};

const DatePickerField = (props: DatePickerFieldProps): ReactElement => {
  const { isOpen, groupRef, groupProps, label, labelProps, onClick } = props;
  const { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });
  const fieldRef = useRef(null);
  const { fieldProps } = useDateField(props, state, fieldRef);
  const buttonRef = useRef(null);
  const { buttonProps } = useButton(props.buttonProps, buttonRef);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  return (
    <div
      ref={groupRef}
      className={cn(styles.datePickerField, {
        [styles.isFocus]: isFocus && !props.isDisabled,
      })}
      {...groupProps}
      onFocus={() => setIsFocus(true)}
      onBlur={() => {
        if (!isOpen) {
          setIsFocus(false);
        }
      }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === " ") {
          onClick();
        }
      }}
    >
      <span {...labelProps} className={styles.label}>
        {label}
      </span>
      <div {...fieldProps} ref={fieldRef} className={styles.field}>
        {state.segments.map((segment, i) => (
          <DatePickerFieldSegment key={i} segment={segment} state={state} />
        ))}
      </div>
      {!props.isDisabled && (
        <button {...buttonProps} ref={buttonRef} className={styles.button}>
          <svg className={cn(styles.icon, "icon icon--arrowdown")} aria-hidden="true">
            <use href="#icon-arrowdown"></use>
          </svg>
        </button>
      )}
    </div>
  );
};

export default DatePickerField;
