import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";

import Heading from "../../../../../atoms/heading/Heading";
import Switch from "../../../../../atoms/switch/Switch";
import CheckList from "../../../../../molecules/check-list/CheckList";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { ProductId } from "../../../../../settings/products";
import { trackChoice } from "../../../../../utils/analytics";
import { formatCurrency } from "../../../../../utils/currency";
import { useShoppingCart, useToggleProduct } from "../../../application/shopping-cart-use-cases";
import { PaymentIntervalMonths } from "../../../settings/payments";
import * as styles from "./GlAddon.module.scss";

interface GlAddonProps {
  benefit: {
    title: string;
    items: string[];
  };
}

const GlAddon = ({ benefit }: GlAddonProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const cart = useShoppingCart();
  const result = useResult();
  const toggleProduct = useToggleProduct();
  const glProduct = cart.getProduct(ProductId.glExtended);
  const isSelected: boolean = glProduct?.selected ?? false;

  return (
    <div className={styles.glAddon}>
      <section className={styles.glAddonHeader}>
        <Heading level={6} adoptionClassName={styles.benefitTitle}>
          {benefit.title}
        </Heading>
        <div className={styles.switchWrapper}>
          <p>
            {parse(
              t("gl_addon.price", {
                price: formatCurrency(
                  (cart.getProduct(ProductId.glExtended)?.price ?? 0) /
                    (cart.paymentIntervalMonths === PaymentIntervalMonths.yearly
                      ? PaymentIntervalMonths.monthly
                      : PaymentIntervalMonths.yearly),
                  i18n.language,
                  result?.currency
                ),
              })
            )}
            {t(
              `quotes.cart.summary.${
                cart.paymentIntervalMonths === PaymentIntervalMonths.yearly ? "yearly" : "monthly"
              }`
            )}
          </p>
          <Switch
            name={"gl"}
            label={t("common.add")}
            handleChange={(e) => {
              toggleProduct(ProductId.glExtended);
              trackChoice(e, { question: "Add GL to health product" });
            }}
            isChecked={isSelected}
          />
        </div>
      </section>
      <CheckList id={`gl-benefits`} items={benefit.items} />
    </div>
  );
};

export default GlAddon;
