import cn from "classnames";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { ReactElement, ReactNode } from "react";

import { ProductId } from "../../../../../settings/products";
import DiscountBadge, { DiscountBadgeVariants } from "../../atoms/discount-badge/DiscountBadge";
import * as styles from "./AlternativeHealthProductCard.module.scss";

interface HealthProductElement {
  value: ProductId;
  features: string[];
  price: ReactNode;
}

interface AlternativeHealthProductCardProps {
  healthProduct: HealthProductElement;
  discountedPrice?: ReactNode;
  appliedPromotionCode?: string;
}

const AlternativeHealthProductCard = ({
  healthProduct,
  discountedPrice,
  appliedPromotionCode,
}: AlternativeHealthProductCardProps): ReactElement => {
  const { features, price } = healthProduct;
  const { t } = useTranslation();

  return (
    <>
      <span className={cn(styles.price)}>{price}</span>
      {discountedPrice && appliedPromotionCode && (
        <section className={styles.discountedPriceWrapper}>
          <span className={styles.discountedPrice}>{discountedPrice}</span>
          <span className={styles.discountedPriceMessage}>
            {t("quotes.cart.summary.becas_discount.message")}
          </span>
          <DiscountBadge
            discountName={appliedPromotionCode}
            adoptionClassName={styles.discountBadge}
            variantName={DiscountBadgeVariants.alternate}
          />
        </section>
      )}

      <ul className={styles.featuresList}>
        {features.map((feature) => (
          <li key={feature}>{parse(feature)}</li>
        ))}
      </ul>
    </>
  );
};

export const query = graphql`
  fragment ProductFeaturesFragment on ContentfulProductFeaturesList {
    entryTitle
    features
    locale: node_locale
  }
`;

export default AlternativeHealthProductCard;
