import React from "react";

import * as styles from "./Switch.module.scss";

interface SwitchProps {
  name: string;
  label: string;
  isChecked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Switch = ({ name, label, isChecked, handleChange }: SwitchProps): JSX.Element => {
  return (
    <div className={styles.atSwitch}>
      <input type="checkbox" id={name} name={name} onChange={handleChange} checked={isChecked} />
      <label htmlFor={name}>
        <span className="sr-only">{label}</span>
        <svg
          viewBox="0 0 64 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
        >
          <rect
            width="64"
            height="32"
            rx="16"
            fill="rgb(161, 177, 196)"
            className={styles.container}
          />
          <circle cx="16" cy="16" r="14" fill="#fff" className={styles.handle} />
          <defs>
            <clipPath id="switch-clip-path">
              <path fill="#fff" transform="translate(10 8)" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      </label>
    </div>
  );
};

export default Switch;
