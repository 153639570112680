import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement, useMemo } from "react";

import Expandable from "../../../../../atoms/expandable/Expandable";
import { CountryCode } from "../../../../../settings/countries";
import { formatCurrency } from "../../../../../utils/currency";
import { usePromotionCode } from "../../../application/promotion-code-use-cases";
import { useShoppingCart } from "../../../application/shopping-cart-use-cases";
import { PaymentIntervalMonths } from "../../../settings/payments";
import PromotionCodeForm from "../promotion-code-form/PromotionCodeForm";
import * as styles from "./PromotionCodeExpandable.module.scss";

interface PromotionCodeExpandableProps {
  countryCodeIso: CountryCode;
}

const PromotionCodeExpandable = ({
  countryCodeIso = CountryCode.ES,
}: PromotionCodeExpandableProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const promotionCode = usePromotionCode();
  const cart = useShoppingCart();

  const showPriceBreakdownMessage = useMemo(() => {
    if (!promotionCode || cart.isNeededSelectHealthProduct) {
      return null;
    }

    const breakdowns = cart.getMonthlyDiscountedPriceBreakdown().map((item, index) => {
      const { payment, appliedDiscount } = item;

      return t(`pg_quotes.promotion_code.price_breakdown_message.${index + 1}_month`, {
        paymentAmount: formatCurrency(payment, language, cart.currency),
        appliedDiscount: formatCurrency(appliedDiscount, language, cart.currency),
        code: promotionCode.code,
      });
    });

    const priceBreakdownMessage = `${t(
      "pg_quotes.promotion_code.price_breakdown_message.price_breakdown"
    )} ${breakdowns.join(" ")} ${t(
      "pg_quotes.promotion_code.price_breakdown_message.following_months",
      {
        paymentAmount: formatCurrency(
          cart.selectedProductsMonthlyTotalPrice,
          language,
          cart.currency
        ),
      }
    )}`;

    return <p className={styles.priceBreakdownMessage}>{priceBreakdownMessage}</p>;
  }, [promotionCode, cart, language, t]);

  return (
    <Expandable
      id={"promotionCodeExpandable"}
      expandText={t("pg_quotes.promotion_code.expandable_button.title")}
      collapseText={t("pg_quotes.promotion_code.expandable_button.title")}
      adoptionClassName={styles.promotionCodeExpandable}
      {...(promotionCode && { initiallyExpanded: true })}
    >
      <PromotionCodeForm
        countryCodeIso={countryCodeIso}
        adoptionClassName={styles.promotionCodeForm}
        {...(promotionCode && { showDiscountApplied: promotionCode.isAmountCoupon })}
      />
      {promotionCode &&
        promotionCode.isAmountCoupon &&
        cart.paymentIntervalMonths === PaymentIntervalMonths.monthly &&
        showPriceBreakdownMessage}
    </Expandable>
  );
};

export default PromotionCodeExpandable;
