import { graphql } from "gatsby";
import { ReactElement } from "react";

import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import Quotes from "../../../features/quotes-funnel/ui/pages/quotes/Quotes";
import * as styles from "../../../features/quotes-funnel/ui/pages/quotes/Quotes.module.scss";
import { PageId } from "../../../settings/pages";

interface PageProps {
  data: {
    allContentfulConditionsList: {
      nodes: { node_locale: string; type: string; species: string; conditionsItems: string[] }[];
    };
    allContentfulProductFeaturesList: {
      nodes: { locale: string; entryTitle: string; features: string[] }[];
    };
    locales: any;
  };
}

const Page = ({ data }: PageProps): ReactElement => {
  const { allContentfulConditionsList, allContentfulProductFeaturesList } = data;

  return (
    <Quotes
      conditionsList={allContentfulConditionsList.nodes}
      productFeaturesList={allContentfulProductFeaturesList.nodes}
    />
  );
};

export default withFunnelWrapper(Page, styles.quote, PageId.quotes);

export const query = graphql`
  query QuotesPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulConditionsList {
      nodes {
        ...ConditionsFragment
      }
    }
    allContentfulProductFeaturesList {
      nodes {
        ...ProductFeaturesFragment
      }
    }
  }
`;
