import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { Dispatch, ReactElement, ReactNode, SetStateAction, useState } from "react";

import Heading from "../../../../../atoms/heading/Heading";
import { Result } from "../../../../../organisms/result/domain/result";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { HealthProductAlias, ProductId } from "../../../../../settings/products";
import { formatCurrency } from "../../../../../utils/currency";
import useVisibilityTracker from "../../../../../utils/hooks/useVisibilityTracker";
import { usePromotionCode } from "../../../application/promotion-code-use-cases";
import { useShoppingCart } from "../../../application/shopping-cart-use-cases";
import { ShoppingCart } from "../../../domain/shopping-cart";
import { PaymentIntervalMonths } from "../../../settings/payments";
import Barkibu80 from "../../images/barkibu-80.svg";
import HealthProductCard from "../../molecules/health-product-card/HealthProductCard";
import * as styles from "./HealthProductSelector.module.scss";

export interface HealthProductElement {
  image: ReactElement | undefined;
  name: string;
  features: string[];
  price: ReactNode;
  benefits: {
    title: string;
    subtitle?: string;
    items: string[];
    type: string;
  }[];
}

interface HealthProductSelectorProps {
  isDisabled: boolean;
  benefits: any;
  features: Record<string, string | string[]>[];
  hasBecasDiscount?: boolean;
  hasHealthProductError?: boolean;
  setHasHealthProductError: Dispatch<SetStateAction<boolean>>;
}

const HealthProductSelector = ({
  isDisabled,
  benefits,
  features,
  hasBecasDiscount,
  hasHealthProductError,
}: HealthProductSelectorProps): ReactElement => {
  const { ref } = useVisibilityTracker("health-product-selector", {
    threshold: 0.5,
  });
  const { t, i18n } = useTranslation();
  const result: Result | null = useResult();
  const cart: ShoppingCart = useShoppingCart();
  const promotionCode = usePromotionCode();
  const [cardsExpanded, setCardsExpanded] = useState(false);

  const getDiscountedPrice = () =>
    promotionCode && hasBecasDiscount
      ? parse(
          t("pg_quotes.health_product_selector.monthly", {
            productPrice: formatCurrency(
              cart.getMonthlyDiscountedPriceByHealthProduct(ProductId.healthBasic),
              i18n.language,
              cart.currency
            ),
          })
        )
      : undefined;

  const getHealthProductPrice = () =>
    parse(
      t(
        `pg_quotes.health_product_selector.${
          cart.paymentIntervalMonths === PaymentIntervalMonths.yearly ? "yearly" : "monthly"
        }`,
        {
          productPrice: formatCurrency(
            cart.getPriceByHealthProduct(ProductId.healthBasic),
            i18n.language,
            result?.currency
          ),
        }
      )
    );

  const getHealthProductFeatures = () => {
    return features
      .filter((item: { locale: string; entryTitle: string; features: string[] }) => {
        return item.entryTitle.toLowerCase() === HealthProductAlias.healthBasic;
      })
      .flatMap((item: { locale: string; entryTitle: string; features: string[] }) => item.features);
  };

  const healthProduct = {
    image: <Barkibu80 />,
    name: HealthProductAlias.healthBasic,
    features: getHealthProductFeatures(),
    price: getHealthProductPrice(),
    benefits: benefits.conditionsItems,
  };

  return (
    <section ref={ref} className={cn(styles.healthProductSelector)}>
      <Heading level={1} adoptionClassName={styles.title}>
        {t("pg_quotes.health_product_selector.title", { petName: result?.pet_name })}
      </Heading>
      <HealthProductCard
        healthProduct={healthProduct}
        isDisabled={isDisabled}
        isExpanded={cardsExpanded}
        handleExpanded={() => setCardsExpanded(!cardsExpanded)}
        discountedPrice={getDiscountedPrice()}
        appliedPromotionCode={promotionCode?.code}
      />
      {hasHealthProductError && (
        <p className={styles.errorMessage}>{t("pg_quotes.health_product_selector.error")}</p>
      )}
    </section>
  );
};

export default HealthProductSelector;
