import cn from "classnames";
import React from "react";

import * as styles from "./DiscountBadge.module.scss";

export enum DiscountBadgeVariants {
  alternate = "alternate",
}

interface DiscountBadgeProps {
  discountName: string;
  variantName?: string;
  adoptionClassName?: string;
}

const DiscountBadge = ({
  discountName,
  variantName,
  adoptionClassName,
}: DiscountBadgeProps): JSX.Element => {
  return (
    <div
      className={cn(styles.discountBadgeContainer, adoptionClassName, {
        [styles[variantName as string]]: variantName,
      })}
    >
      <svg aria-hidden="true">
        <use href="#icon-tag" />
      </svg>
      <span className={styles.discountName}>{discountName}</span>
    </div>
  );
};

export default DiscountBadge;
