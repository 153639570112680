import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { ReactElement } from "react";

import { Image } from "../../../../../core/utils/images";
import SectionTitle from "../../../../../molecules/section-title/SectionTitle";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import WhatsAppDialog from "../whatsapp-dialog/WhatsAppDialog";
import * as styles from "./BarkibuCardBanner.module.scss";

interface BarkibuCardBannerProps {
  adoptionClassName?: string;
}

const cardSVG: Image = require("../../images/organisms/barkibu-card-banner/card.svg") as Image;

const cardSVGSrc: string = cardSVG.default;

const BarkibuCardBanner = ({ adoptionClassName }: BarkibuCardBannerProps): ReactElement => {
  const { t } = useTranslation();
  const result = useResult();

  return (
    <>
      {result && (
        <section className={cn(styles.barkibuCardBanner, adoptionClassName)}>
          <img src={cardSVGSrc} alt="" className={styles.cardImage} />
          <SectionTitle
            headingLevel={3}
            title={t("pg_quotes.barkibu_card.help_card.title")}
            subtitle={t("pg_quotes.barkibu_card.help_card.subtitle")}
            adoptionClassName={styles.sectionTitle}
          />
          <WhatsAppDialog
            label={t("pg_quotes.barkibu_card.help_card.card_doubts_button")}
            countryCode={result.country}
            eventSender={"quotes_page-barkibu_card_banner-card_doubts"}
          />
          <WhatsAppDialog
            label={t("pg_quotes.barkibu_card.help_card.insurance_doubts_button")}
            countryCode={result.country}
            eventSender={"quotes_page-barkibu_card_banner-insurance_doubts"}
          />
        </section>
      )}
    </>
  );
};

export default BarkibuCardBanner;
