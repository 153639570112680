import { ReactElement, RefObject, useRef } from "react";
import { CalendarProps, DateValue, DismissButton, Overlay, usePopover } from "react-aria";
import { DatePickerState } from "react-stately";

import Calendar from "../../atoms/calendar/Calendar";
import * as styles from "./DatePickerCalendar.module.scss";

interface DatePickerCalendarProps {
  state: DatePickerState;
  triggerRef: RefObject<HTMLDivElement>;
  calendarProps: CalendarProps<DateValue>;
}

const DatePickerCalendar = (props: DatePickerCalendarProps): ReactElement => {
  const { state, triggerRef, calendarProps } = props;
  const popoverRef = useRef(null);
  const { popoverProps, underlayProps } = usePopover(
    {
      triggerRef,
      popoverRef,
      placement: "bottom end",
    },
    state
  );

  return (
    <Overlay>
      <div {...underlayProps} className={styles.underlay} />
      <div
        {...popoverProps}
        ref={popoverRef}
        style={{ ...popoverProps.style }}
        className={styles.popover}
      >
        <DismissButton onDismiss={state.close} />
        <Calendar {...calendarProps} />
      </div>
    </Overlay>
  );
};

export default DatePickerCalendar;
